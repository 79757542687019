import React from 'react'
import SEO from '../components/seo'

import "../styles/privacy_policy.scss"

const PrivacyPolicy = () => {

  return (
    <div className="policy flex flex-col my-8 w-10/12 mx-auto">
      <SEO title="Política de Tratamiento de Datos" />
      <h1 className="font-bold text-center mb-4 text-xl">POLÍTICA DE TRATAMIENTO DE DATOS PERSONALES</h1>
      <p>
        En cumplimiento a lo dispuesto en la Ley Estatutaria 1581 de 2012 y sus Decretos Reglamentarios, la empresa <span className="font-bold">GRUPO INSULA S.A.S.</span> establece las Políticas aplicables para el Tratamiento y Protección de Datos Personales en la organización.
      </p>

      <h2 className="subtitle">1. IDENTIFICACIÓN DEL RESPONSABLE</h2>
      <ul className="ml-6 list-disc mb-4">
        <li>
          <span>NOMBRE DE LA EMPRESA: GRUPO INSULA S.A.S.</span>sociedad comercial identificada con NIT. No. 901315619-1, se constituye como una empresa colombiana, con el objeto social de fabricación, remodelación, diseños promoción, ejecución, estudios arquitectónicos, interventorías,
          compra y venta de todo tipo de proyectos, venta de bienes inmuebles y muebles con el fin de obtener un beneficio económico de ello.
        </li>
        <li>
          <span>DIRECCIÓN FÍSICA:</span> Avenida 106 # 15 - 00 Ciudad Jardin, CALI, COLOMBIA
        </li>
        <li>
          <span>PÁGINA WEB:</span>{" "}
          <a href="https://grupoinsula.com/" target="_blank">
            www.grupoinsula.com
          </a>
        </li>
        <li>
          <span>TELÉFONO:</span> 311 485 0678
        </li>
      </ul>

      <h2 className="subtitle">2. OBJETIVO</h2>
      <p>
        La presente Política establece las directrices generales para la protección y el tratamiento de datos personales al interior de{" "}
        <span className="font-bold">
          <span className="font-bold">GRUPO INSULA S.A.S.</span>.
        </span>
        , permitiendo de esta manera fortalecer el nivel de confianza entre Responsables y Titular con relación al tratamiento de su información; Informar al Titular las finalidades y transferencias a que son sometidos sus datos personales y los mecanismos y formas para el ejercicio de sus
        derechos.
      </p>

      <h2 className="subtitle">3. ALCANCE</h2>
      <p>
        Esta Política de Protección de Datos Personales será aplicada a todas las Bases de Datos y/o Archivos que incluyan Datos Personales que sean objeto de Tratamiento por parte de <span className="font-bold">GRUPO INSULA S.A.S.</span> como Responsable del tratamiento de Datos Personales.
      </p>

      <h2 className="subtitle">4. DEFINICIONES</h2>
      <ul className="ml-6 list-disc mb-4">
        <li>
          <span>Habeas Data:</span> Derecho que tiene toda persona de conocer, actualizar y rectificar la información que se haya recogido sobre ella en archivos y bancos de datos de naturaleza pública o privada.
        </li>
        <li>
          <span>Dato personal:</span> Cualquier información vinculada o que pueda asociarse a una o varias personas naturales determinadas o determinables.
        </li>
        <li>
          <span>Base de Datos:</span> Conjunto organizado de Datos Personales que sea objeto de Tratamiento.
        </li>
        <li>
          <span>Tratamiento:</span> Cualquier operación o conjunto de operaciones sobre datos personales, tales como la recolección, almacenamiento, uso, circulación o supresión.
        </li>
        <li>
          <span>Autorización:</span> Consentimiento previo, expreso e informado del Titular para llevar a cabo el Tratamiento de datos personales.
        </li>
        <li>
          <span>Aviso de privacidad</span> Es el documento físico, electrónico o en cualquier otro formato conocido o por conocer, que es puesto a disposición del Titular para el tratamiento de sus Datos Personales.
        </li>
        <li>
          <span>Titular:</span> Persona natural cuyos datos personales sean objeto de Tratamiento
        </li>
        <li>
          <span>Causahabiente:</span> Persona que por sucesión o transmisión adquiere los derechos de otra persona.
        </li>
        <li>
          <span>Responsable del Tratamiento:</span> Persona natural o jurídica, pública o privada, que por sí misma o en asocio con otros, decida sobre la base de datos y/o el Tratamiento de los datos.
        </li>
        <li>
          <span>Encargado del Tratamiento:</span> Persona natural o jurídica, pública o privada, que por sí misma o en asocio con otros, realice el Tratamiento de datos personales por cuenta del Responsable del Tratamiento.
        </li>
      </ul>

      <h2 className="subtitle">5. PRINCIPIOS RECTORES APLICABLES EN MATERIA DE DATOS PERSONALES</h2>
      <p>En materia de protección de datos personales se aplicarán los siguientes principios rectores:</p>
      <ol type="a" className="ml-6 list-decimal mb-4">
        <li>
          <span>Principio de legalidad en materia de Tratamiento de datos:</span> El Tratamiento a que se refiere la presente ley es una actividad reglada que debe sujetarse a lo establecido en ella y en las demás disposiciones que la desarrollen.
        </li>
        <li>
          <span>Principio de finalidad:</span> El Tratamiento debe obedecer a una finalidad legítima de acuerdo con la Constitución y la Ley, la cual debe ser informada al Titular.
        </li>
        <li>
          <span>Principio de libertad:</span> El Tratamiento sólo puede ejercerse con el consentimiento, previo, expreso e informado del Titular. Los datos personales no podrán ser obtenidos o divulgados sin previa autorización, o en ausencia de mandato legal o judicial que releve el
          consentimiento.
        </li>
        <li>
          <span>Principio de veracidad o calidad:</span> La información sujeta a Tratamiento debe ser veraz, completa, exacta, actualizada, comprobable y comprensible. Se prohíbe el Tratamiento de datos parciales, incompletos, fraccionados o que induzcan a error.
        </li>
        <li>
          <span>Principio de transparencia:</span> En el Tratamiento debe garantizarse el derecho del Titular a obtener del Responsable del Tratamiento o del Encargado del Tratamiento, en cualquier momento y sin restricciones, información acerca de la existencia de datos que le conciernan.
        </li>
        <li>
          <span>Principio de acceso y circulación restringida:</span> El Tratamiento se sujeta a los límites que se derivan de la naturaleza de los datos personales, de las disposiciones de la presente ley y la Constitución. En este sentido, el Tratamiento sólo podrá hacerse por personas
          autorizadas por el Titular y/o por las personas previstas en la presente ley. <br /> Los datos personales, salvo la información pública, no podrán estar disponibles en Internet u otros medios de divulgación o comunicación masiva, salvo que el acceso sea técnicamente controlable para
          brindar un conocimiento restringido sólo a los Titulares o terceros autorizados conforme a la presente ley.
        </li>
        <li>
          <span>Principio de seguridad:</span> La información sujeta a Tratamiento por el Responsable del Tratamiento o Encargado del Tratamiento a que se refiere la presente ley, se deberá manejar con las medidas técnicas, humanas y administrativas que sean necesarias para otorgar seguridad a los
          registros evitando su adulteración, pérdida, consulta, uso o acceso no autorizado o fraudulento.
        </li>
        <li>
          <span>Principio de confidencialidad:</span> Todas las personas que intervengan en el Tratamiento de datos personales que no tengan la naturaleza de públicos están obligadas a garantizar la reserva de la información, inclusive después de finalizada su relación con alguna de las labores
          que comprende el Tratamiento, pudiendo sólo realizar suministro o comunicación de datos personales cuando ello corresponda al desarrollo de las actividades autorizadas en la presente ley y en los términos de la misma.
        </li>
      </ol>

      <h2 className="subtitle">6. DERECHOS DE LOS TITULARES</h2>
      <p>Los Titulares de Datos Personales gozarán de los siguientes derechos, y de aquellos que les otorgue la Ley:</p>
      <ol type="a" className="ml-6 list-decimal mb-4">
        <li>
          Conocer, actualizar y rectificar sus datos personales frente a los Responsables del Tratamiento o Encargados del Tratamiento. Este derecho se podrá ejercer, entre otros frente a datos parciales, inexactos, incompletos, fraccionados, que induzcan a error, o aquellos cuyo Tratamiento esté
          expresamente prohibido o no haya sido autorizado;
        </li>
        <li>Solicitar prueba de la autorización otorgada al Responsable del Tratamiento salvo cuando expresamente se exceptúe como requisito para el Tratamiento, de conformidad con lo previsto en el artículo 10 de la presente ley;</li>
        <li>Ser informado por el Responsable del Tratamiento o el Encargado del Tratamiento, previa solicitud, respecto del uso que le ha dado a sus datos personales;</li>
        <li>Presentar ante la Superintendencia de Industria y Comercio quejas por infracciones a lo dispuesto en la presente ley y las demás normas que la modifiquen, adicionen o complementen;</li>
        <li>
          Revocar la autorización y/o solicitar la supresión del dato cuando en el Tratamiento no se respeten los principios, derechos y garantías constitucionales y legales. La revocatoria y/o supresión procederá cuando la Superintendencia de Industria y Comercio haya determinado que en el
          Tratamiento el Responsable o Encargado han incurrido en conductas contrarias a esta ley y a la Constitución;
        </li>
        <li>Acceder en forma gratuita a sus datos personales que hayan sido objeto de Tratamiento.</li>
      </ol>

      <h2>6.1. MECANISMOS PARA EJERCER LOS DERECHOS DE LOS TITULARES</h2>
      <p>
        El Titular de Datos Personales podrá hacer ejercicio de sus derechos contactándose con la entidad a través de comunicación escrita dirigida al área encargada de la Protección de Datos Personales, la cual será CONTABILIDAD y SOPORTE ADMINISTRATIVO. La comunicación podrá ser enviada al
        siguiente correo electrónico simon@grupoinsula.com o a través de comunicación telefónica al numero 311 485 0678.
      </p>

      <h2>7. AUTORIZACIÓN DEL TITULAR DE DATOS PERSONALES</h2>
      <p>
        Sin perjuicio de las excepciones previstas en la Ley Estatutaria 1581 de 2012, por regla general en el Tratamiento de Datos de carácter Personal <span className="font-bold">GRUPO INSULA S.A.S.</span>. recolectará la autorización previa e informada del Titular, la cual podrá ser
        obtenida por cualquier medio que pueda ser objeto de consulta posterior.
      </p>

      <h2>7.1 Eventos en los cuales no es necesaria la autorización</h2>
      <p>La autorización del Titular no será necesaria cuando se trate de:</p>
      <ol type="a" className="ml-6 list-decimal mb-4">
        <li>Información requerida por una entidad pública o administrativa en ejercicio de sus funciones legales o por orden judicial;</li>
        <li>Datos de naturaleza pública;</li>
        <li>Casos de urgencia médica o sanitaria;</li>
        <li>Tratamiento de información autorizado por la ley para fines históricos, estadísticos o científicos;</li>
        <li>Datos relacionados con el Registro Civil de las Personas.</li>
      </ol>

      <h2>
        8. DEBERES DE <span className="font-bold">GRUPO INSULA S.A.S.</span>. COMO RESPONSABLE DEL TRATAMIENTO DE DATOS PERSONALES
      </h2>
      <p>
        <span className="font-bold">GRUPO INSULA S.A.S.</span>. como Responsables del Tratamiento de datos personales, cumplirá los siguientes deberes:
      </p>
      <ol type="a" className="ml-6 list-decimal mb-4">
        <li>Garantizar al Titular, en todo tiempo, el pleno y efectivo ejercicio del derecho de hábeas data.</li>
        <li>Solicitar y conservar, en las condiciones previstas en la Ley, copia de la respectiva autorización otorgada por el Titular.</li>
        <li>Informar debidamente al Titular sobre la finalidad de la recolección y los derechos que le asisten por virtud de la autorización otorgada.</li>
        <li>Conservar la información bajo las condiciones de seguridad necesarias para impedir su adulteración, pérdida, consulta, uso o acceso no autorizado o fraudulento.</li>
        <li>Garantizar que la información que se suministre al Encargado del Tratamiento sea veraz, completa, exacta, actualizada, comprobable y comprensible.</li>
        <li>Actualizar la información, comunicando de forma oportuna al Encargado del Tratamiento, todas las novedades respecto de los datos que previamente le haya suministrado y adoptar las demás medidas necesarias para que la información suministrada a este se mantenga actualizada.</li>
        <li>Rectificar la información cuando sea incorrecta y comunicar lo pertinente al Encargado del Tratamiento.</li>
        <li>Rectificar la información cuando sea incorrecta y comunicar lo pertinente al Encargado del Tratamiento.</li>
        <li>Exigir al Encargado del Tratamiento en todo momento, el respeto a las condiciones de seguridad y privacidad de la información del Titular.</li>
        <li>Tramitar las consultas y reclamos formulados en los términos señalados en la Ley Estatutaria 1581 de 2012.</li>
        <li>Adoptar un manual interno de políticas y procedimientos para garantizar el adecuado cumplimiento de la Ley y en especial, para la atención de consultas y reclamos.</li>
        <li>Informar al Encargado del Tratamiento cuando determinada información se encuentra en discusión por parte del Titular, una vez se haya presentado la reclamación y no haya finalizado el trámite respectivo.</li>
        <li>Informar a solicitud del Titular sobre el uso dado a sus datos.</li>
        <li>Informar a la autoridad de protección de datos cuando se presenten violaciones a los códigos de seguridad y existan riesgos en la administración de la información de los Titulares.</li>
        <li>Cumplir las instrucciones y requerimientos que imparta la Superintendencia de Industria y Comercio.</li>
      </ol>

      <h2>9. POLÍTICAS ESPECÍFICAS PARA EL TRATAMIENTO DE DATOS PERSONALES.</h2>
      <p>
        Las finalidades para las cuales son utilizadas los datos personales de <span className="font-bold">GRUPO INSULA S.A.S.</span>., serán:
      </p>
      <ul className="ml-6 list-disc mb-4">
        <li>Tratamiento Datos Personales de Trabajadores y Nómina</li>
      </ul>
      <p>
        <span className="font-bold">GRUPO INSULA S.A.S.</span>. recolecta los datos personales de sus Trabajadores los cuales son calificados por la compañía como de reserva, y solo serán revelados por la empresa con la expresa autorización del titular o cuando una Autoridad Competente lo
        solicite.
      </p>
      <p>
        Las finalidades para las cuales son utilizados los datos personales de los trabajadores y la nomina de <span className="font-bold">GRUPO INSULA S.A.S.</span>. serán:
      </p>
      <ol type="a" className="ml-6 list-decimal mb-4">
        <li>Dar cumplimiento a la ley colombiana o extranjera y las órdenes de autoridades judiciales, administrativas o entidades privadas en ejercicio de servicios públicos;</li>
        <li>Emitir certificaciones relativas a la relación del titular del dato con la compañía.</li>
        <li>Fines estadísticos o históricos.</li>
      </ol>
      <p>
        Así mismo <span className="font-bold">GRUPO INSULA S.A.S.</span>. almacena datos personales que hayan sido obtenidos en desarrollo del proceso de selección de sus empleados en una carpeta identificada con el nombre de cada uno de ellos.
      </p>
      <p>
        A tal carpeta solo tendrá acceso y será tratada por el Área de Recursos Humanos, con la finalidad de administrar la relación contractual entre <span className="font-bold">GRUPO INSULA S.A.S.</span>. y el empleado.
      </p>
      <p>
        Para efectos del tratamiento de datos personales que sean considerados como sensibles y se recolecten con ocasión de la relación laboral, en todo caso será necesaria autorización expresa del titular, para lo cual se le deberá informar cuáles son los datos sensibles objeto de tratamiento y
        la finalidad del mismo.
      </p>
      <p>
        Para efectos del tratamiento de datos personales que sean considerados como sensibles y se recolecten con ocasión de la relación laboral, en todo caso será necesaria autorización expresa del titular, para lo cual se le deberá informar cuáles son los datos sensibles objeto de tratamiento y
        la finalidad del mismo.
      </p>
      <p>En todo caso, la información no será objeto de tratamiento por un período superior a veinte (20) años contados a partir de su recolección de acuerdo con las circunstancias legales o contractuales que hacen necesario el manejo de la información.</p>
      <ul className="ml-6 list-disc mb-4">
        <li>Tratamiento de datos personales de Accionistas</li>
      </ul>
      <p>
        <span className="font-bold">GRUPO INSULA S.A.S.</span>. recolecta los datos personales de sus Accionistas y los almacena en una base de datos la cual es calificada por la compañía como de reserva, y solo será revelada por la empresa con la expresa autorización del titular o cuando una
        Autoridad Competente lo solicite.
      </p>
      <p>
        Las finalidades para las cuales son utilizadas los datos personales de los Accionistas de <span className="font-bold">GRUPO INSULA S.A.S.</span>. serán:
      </p>
      <ol type="a" className="ml-6 list-decimal mb-4">
        <li>Permitir el ejercicio de los deberes y derechos derivados de la calidad de accionista</li>
        <li>Envío de invitaciones a eventos programados por la Compañía;</li>
        <li>Envío de invitaciones a eventos programados por la Compañía;</li>
        <li>Las demás establecidas específicamente en las autorizaciones que sean otorgadas por los accionistas.</li>
      </ol>
      <p>
        En todo caso, la información no será objeto de tratamiento por un período superior al tiempo que la persona sea accionista de la compañía y cinco (5) años mas contados a partir de su recolección de acuerdo con las circunstancias legales o contractuales que hacen necesario el manejo de la
        información.
      </p>
      <p>En consecuencia, el acceso a tal información personal se realizará conforme a lo establecido en el Código de Comercio y demás normas que regulan la materia.</p>
      <ul className="ml-6 list-disc mb-4">
        <li>Tratamiento de datos personales de Clientes</li>
      </ul>
      <p>
        <span className="font-bold">GRUPO INSULA S.A.S.</span>. recolecta los datos personales de sus Clientes y los almacena en una base de datos la cual es calificada por la compañía como de reserva, y solo será revelada por la empresa con la expresa autorización del titular o cuando una
        Autoridad Competente lo solicite.
      </p>
      <p>
        Las finalidades para las cuales son utilizadas los datos personales de los Clientes de <span className="font-bold">GRUPO INSULA S.A.S.</span>. serán:
      </p>
      <ol type="a" className="ml-6 list-decimal mb-4">
        <li>Realización de gestiones para las etapas precontractual, contractual y poscontractual.</li>
        <li>Envío de invitaciones a eventos programados por la Compañía.</li>
        <li>Corroborar cualquier requerimiento que se ocasione en desarrollo del contrato celebrado.</li>
        <li>Verificar casos en que se de incumplimiento de alguna de las partes</li>
        <li>Control en aquellos eventos que se otorga financiación al contrato celebrado.</li>
        <li>Vinculación en general de cada cliente.</li>
      </ol>
      <p>En todo caso, la información no será objeto de tratamiento por un período superior al que dure la relación entre las partes, y será contada a partir de su recolección de acuerdo con las circunstancias legales o contractuales que hacen necesario el manejo de la información.</p>
      <ul className="ml-6 list-disc mb-4">
        <li>Tratamiento de datos personales en el Registro de Videovigilancia</li>
      </ul>
      <p>
        <span className="font-bold">GRUPO INSULA S.A.S.</span>. recolecta los datos personales de sus Cámaras de Vigilancia y los almacena en una base de datos la cual es calificada por la compañía como de reserva, y solo será revelada por la empresa con la expresa autorización del titular o
        cuando una Autoridad Competente lo solicite.
      </p>
      <p>
        Las finalidades para las cuales son utilizadas los datos personales de las Cámaras de Vigilancia de <span className="font-bold">GRUPO INSULA S.A.S.</span>. serán:
      </p>
      <ol type="a" className="ml-6 list-decimal mb-4">
        <li>Garantizar la seguridad en los ambientes laborales.</li>
        <li>Permitir ambientes de trabajo adecuados para el desarrollo seguro de actividades laborales de la compañia.</li>
      </ol>
      <p>
        Para cumplir con el deber de información que le corresponde a <span className="font-bold">GRUPO INSULA S.A.S.</span>. como administrador de datos personales, la empresa implementará Avisos de Privacidad en las zonas en donde se realice la captura de imágenes que impliquen tratamiento
        de datos personales.
      </p>
      <p>En todo caso, la información no será objeto de tratamiento por un período superior a treinta (30) días contados a partir de su recolección de acuerdo con las circunstancias legales o contractuales que hacen necesario el manejo de la información.</p>
      <ul className="ml-6 list-disc mb-4">
        <li>Tratamiento de datos personales en el Control de Acceso y Registro de Ingreso</li>
      </ul>
      <p>
        <span className="font-bold">GRUPO INSULA S.A.S.</span>. recolecta los datos personales de sus visitantes y los almacena en una base de datos la cual es calificada por la compañía como de reserva, y solo será revelada por la empresa con la expresa autorización del titular o cuando una
        Autoridad Competente lo solicite.
      </p>
      <p>
        Las finalidades para las cuales son utilizadas los datos personales de los visitantes de <span className="font-bold">GRUPO INSULA S.A.S.</span>. serán:
      </p>
      <ol type="a" className="ml-6 list-decimal mb-4">
        <li>
          Cumplir con las obligaciones impuestas a <span className="font-bold">GRUPO INSULA S.A.S.</span>. como empleador, en relación con las normas de Seguridad y Salud Ocupacional, y el denominado Sistema de Gestión de Seguridad y Salud en el Trabajo (SG-SST).
        </li>
        <li>Asegurar el ingreso a las instalaciones de la empresa a personas que cuentan con la autorización de libre tránsito y restringir el paso a aquellas personas que no estén autorizadas.</li>
      </ol>
      <p>En todo caso, la información no será objeto de tratamiento por un período superior a un (1) año contado a partir de su recolección de acuerdo con las circunstancias legales o contractuales que hacen necesario el manejo de la información.</p>
      <ul className="ml-6 list-disc mb-4">
        <li>Tratamiento de datos personales en Registro Visitas Sala de Ventas</li>
      </ul>
      <p>
        <span className="font-bold">GRUPO INSULA S.A.S.</span>. recolecta los datos personales de quienes visitan sus salas de ventas y los almacena en una base de datos la cual es calificada por la compañía como de reserva, y solo será revelada por la empresa con la expresa autorización del
        titular o cuando una Autoridad Competente lo solicite.
      </p>
      <p>
        Las finalidades para las cuales son utilizadas los datos personales de los visitantes de las salas de ventas de <span className="font-bold">GRUPO INSULA S.A.S.</span>. serán:
      </p>
      <ol type="a" className="ml-6 list-decimal mb-4">
        <li>Establecer una relación futura con posibles clientes</li>
        <li>Enviar a los visitantes de las salas de ventas futuros proyectos e información adicional a la suministrada en las mismas.</li>
      </ol>
      <p>En todo caso, la información no será objeto de tratamiento por un período superior a seis (6) meses contados a partir de su recolección de acuerdo con las circunstancias legales o contractuales que hacen necesario el manejo de la información.</p>

      <h2>10. TRANSFERENCIA Y TRANSMISIÓN INTERNACIONAL DE DATOS PERSONALES</h2>
      <p>
        La empresa actualmente no realiza Transmisión o Transferencia internacional de Datos Personales. En el evento que la empresa decida realizar la Transferencia o Transmisión internacional de Datos Personales, además de contar con la autorización expresa e inequívoca por parte del Titular,{" "}
        <span className="font-bold">GRUPO INSULA S.A.S.</span>. se asegurará que el país al que sean transferidos o transmitidos los datos proporcione los niveles adecuados de protección de datos, los cuales en ningún caso podrán ser inferiores a los fijados en Colombia por la Ley Estatutaria
        1581 de 2012 y sus decretos reglamentarios.
      </p>

      <h2>11. DATOS DE NIÑOS, NIÑAS Y ADOLESCENTES</h2>
      <p>
        <span className="font-bold">GRUPO INSULA S.A.S.</span>. no realiza el Tratamiento de Datos Personales de menores de Edad. Sin embargo, en el momento que lo hiciere, la empresa garantizará el respeto de los derechos de los niños, niñas y adolescentes, los cuales priman en todo caso, y
        recolectará en todos los casos la respectiva autorización para su tratamiento.
      </p>

      <h2>12. PROCEDIMIENTO PARA LA ATENCIÓN DE CONSULTAS, RECLAMOS Y PETICIONES</h2>
      <h4>11.1 Consultas</h4>
      <p>
        Los Titulares o sus causahabientes podrán consultar la información personal del Titular que repose en <span className="font-bold">GRUPO INSULA S.A.S.</span>. quien se encargará de suministrar toda la información contenida en el registro individual o que esté vinculada con la
        identificación del Titular.
      </p>
      <p>
        La consulta una vez recibida por la empresa, será atendida en un término máximo de <span className="font-bold">diez (10) días hábiles</span> contados a partir de la fecha de recibo de la misma.
      </p>
      <p>
        Cuando no fuere posible atender la consulta dentro de dicho término, se informará al interesado, expresando los motivos de la demora y señalando la nueva fecha en que se atenderá tal consulta, la cual en ningún caso podrá superar los{" "}
        <span className="font-bold">cinco (5) días hábiles</span> siguientes al vencimiento del primer término.
      </p>
      <h4>11.2 Reclamos</h4>
      <p>
        El Titular o sus causahabientes que consideren que la información contenida en una base de datos debe ser objeto de corrección, actualización o supresión, o cuando adviertan el presunto incumplimiento de cualquiera de los deberes contenidos en esta ley, podrán presentar un reclamo ante{" "}
        <span className="font-bold">GRUPO INSULA S.A.S.</span>. el cual será tramitado bajo las siguientes reglas:
      </p>
      <ol className="ml-6 list-decimal mb-4">
        <li>
          El reclamo se formulará mediante comunicación escrita dirigida a <span className="font-bold">GRUPO INSULA S.A.S.</span>., con la identificación del Titular, la descripción de los hechos que dan lugar al reclamo, la dirección, y acompañando los documentos que se quiera hacer valer.{" "}
          <br /> Si el reclamo resulta incompleto, se requerirá al interesado dentro de los cinco <span className="font-bold">(5) días siguientes</span> a la recepción del reclamo para que subsane las fallas. Transcurridos dos <span className="font-bold">(2) meses</span> desde la fecha del
          requerimiento, sin que el solicitante presente la información requerida, se entenderá que ha desistido del reclamo. <br /> En caso que <span className="font-bold">GRUPO INSULA S.A.S.</span>. reciba un Reclamo del cual no sea competente para resolverlo, la empresa dará traslado a
          quien efectivamente corresponda en un término máximo de <span className="font-bold">dos (2) días hábiles</span> e informará al Titular.
        </li>
        <li>
          Una vez recibido el reclamo completo, la empresa incluirá en la respectiva base de datos una leyenda que diga <span className="font-bold">"reclamo en trámite"</span> y el motivo del mismo, en un término no mayor a <span className="font-bold">dos (2) días hábiles</span>. La empresa
          conservará dicha leyenda en el dato objeto de discusión hasta que el reclamo sea decidido.
        </li>
        <li>
          El término máximo para atender el reclamo será de <span className="font-bold">quince (15) días hábiles</span> contados a partir del día siguiente a la fecha de su recibo. Cuando no fuere posible atender el reclamo dentro de dicho término, la empresa informará al Titular los motivos de la
          demora y la nueva fecha en que se atenderá su reclamo, la cual en ningún caso podrá superar los <span className="font-bold">ocho (8) días hábiles</span> siguientes al vencimiento del primer término.
        </li>
      </ol>
      <h4>11.3 Requisito de procedibilidad</h4>
      <p>El Titular o su causahabiente sólo podrá presentar queja ante la Superintendencia de Industria y Comercio una vez haya agotado el trámite de Consulta o Reclamo directamente ante la empresa.</p>
      <h4>11.4 Petición de actualización y/o rectificación</h4>
      <p>
        <span className="font-bold">GRUPO INSULA S.A.S.</span>. rectificará y actualizará, a solicitud del titular, la información que sea inexacta o se encuentre incompleta, atendiendo al procedimiento y los términos antes señalados, para lo cual el Titular deberá allegar la solicitud según
        los canales dispuestos por la compañía, indicando la actualización y rectificación del dato y a su vez deberá aportar la documentación que soporte tal petición.
      </p>
      <h4>11.5 Revocatoria de la autorización y/o supresión del Dato</h4>
      <p>El Titular podrá revocar en cualquier momento el consentimiento o Autorización dada para el tratamiento de sus datos personales, siempre y cuando no se encuentre un impedimento consagrado en una disposición legal o contractual.</p>
      <p>
        Así también el Titular tiene derecho a solicitar en todo momento a <span className="font-bold">GRUPO INSULA S.A.S.</span>., la supresión o eliminación de sus datos personales cuando:
      </p>
      <ol type="a" className="ml-6 list-decimal mb-4">
        <li>Considere que los mismos no están siendo tratados conforme a los principios, deberes y obligaciones previstas en la normatividad vigente.</li>
        <li>Hayan dejado de ser necesarios o pertinentes para la finalidad para la cual fueron obtenidos.</li>
        <li>Se haya cumplido el tiempo necesario para el cumplimiento de los fines para los que fueron obtenidos.</li>
      </ol>
      <p>
        Tal supresión implica la eliminación bien sea de manera total o parcial de la información personal, de acuerdo con lo solicitado por el titular en los registros, archivos, bases de datos o tratamientos realizados por <span className="font-bold">GRUPO INSULA S.A.S.</span>.
      </p>
      <p>
        El derecho de cancelación no es absoluto y por lo tanto <span className="font-bold">GRUPO INSULA S.A.S.</span>. podrá negar revocatoria de autorización o eliminación de los datos personales en los siguientes casos:
      </p>
      <ol type="a" className="ml-6 list-decimal mb-4">
        <li>El titular tenga un deber legal o contractual de permanecer en la base de datos.</li>
        <li>La eliminación de datos obstaculice actuaciones judiciales o administrativas vinculadas a obligaciones fiscales, la investigación y persecución de delitos o la actualización de sanciones administrativas.</li>
        <li>Los datos sean necesarios para proteger los intereses jurídicamente tutelados del titular; para realizar una acción en función del interés público, o para cumplir con una obligación legalmente adquirida por el titular.</li>
      </ol>

      <h2 className="subtitle">13. MODIFICACIÓN DE LAS POLÍTICAS</h2>
      <p>
        La entidad se reserva el derecho de modificar la Política de Tratamiento de Datos Personales en cualquier momento. Sin embargo toda modificación será comunicada de forma oportuna a los Titulares de los Datos Personales a través de los medios habituales de contacto con diez (10) días
        hábiles de antelación a su entrada en vigencia.
      </p>
      <p>
        En el evento que un Titular no esté de acuerdo con las nuevas Políticas y con razones válidas que se constituyan en una justa causa para no continuar con la autorización para el tratamiento de datos personales, el Titular podrá solicitar a la empresa el retiro de su información a través de
        los canales indicados en el capítulo 11. Sin embargo, los Titulares no podrán solicitar el retiro de sus datos personales cuando la empresa tenga un deber legal o contractual de tratar los datos.
      </p>
    </div>
  )

}

export default PrivacyPolicy